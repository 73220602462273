import React, { useCallback, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import useWebSocket from 'react-use-websocket';
import { useIsStaffProfile } from 'shared/foreground/models';
import combineClasses from 'shared/foreground/utils/combineClasses';
import useLiveValueRef from 'shared/foreground/utils/useLiveValueRef';
import makeLogger from 'shared/utils/makeLogger';
import { ulid } from 'ulid';

import styles from './ChatPage.module.css';
import NewsletterIcon from './icons/24SolidNewsLetterIcon';
import Textarea from './Textarea';

type Conversation = {
  chatMessages: ChatMessage[];
  createdAt: string;
  id: string;
  lastChatMessageAt?: string;
  title: string;
};

type ChatMessage = {
  content: string;
  createdAt: string;
  id: string;
  isError?: boolean;
  isResponse: boolean;
  latestContentDelta?: string;
  relatedDocumentChunks?: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type WebSocketMessagePayload = {
  category: 'chatMessageDelta' | 'newChatMessage';
  chatMessages: ChatMessage[];
  conversationId: string;
} | {
  category: 'conversationUpdate';
  conversationId: string;
  overwrites: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

const logger = makeLogger(__filename, { shouldLog: true });

export default function ChatPage() {
  const websocketUrl = import.meta.env.DEV
      ? 'wss://local.readwise.io:8002/ws'
      : 'wss://reader-chatwebsockets.readwise.io/ws';

  const ws = useWebSocket<WebSocketMessagePayload>(websocketUrl, {
    heartbeat: true,
    reconnectAttempts: Infinity,

    /*
      Exponential backoff, capped to 10,000 milliseconds.

      `attemptNumber` will be 0 the first time it attempts to reconnect, so this equation results in a
      reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and so on.
    */
    reconnectInterval: useCallback((attemptNumber: number) => {
      return Math.min(2 ** attemptNumber * 1000, 10000);
    }, []),
    shouldReconnect: useCallback((closeEvent: WebSocketEventMap['close']) => {
      logger.debug('WS closed. Will try to reconnect...', { closeEvent });
      return true;
    }, []),
  });
  const wsRef = useLiveValueRef(ws);

  const [currentConversation, setCurrentConversation] = useState<Conversation>({
    chatMessages: [],
    createdAt: new Date().toISOString(),
    id: ulid(),
    title: 'No title',
  });
  const currentConversationRef = useLiveValueRef(currentConversation);

  // Handle incoming WS message
  useEffect(() => {
    if (!ws.lastJsonMessage || wsRef.current.lastMessage?.data === 'pong') {
      return;
    }
    logger.debug('!!! RECEIVE', ws.lastJsonMessage);
    setCurrentConversation((prev) => {
      const result = structuredClone(prev);

      if (ws.lastJsonMessage.category === 'conversationUpdate') {
        return result;
      }

      const latestChatMessageInPayload = ws.lastJsonMessage.chatMessages[ws.lastJsonMessage.chatMessages.length - 1];
      const relatedChatMessageIndex = result.chatMessages.findIndex(({ id }) => id === latestChatMessageInPayload.id);
      if (ws.lastJsonMessage.category === 'chatMessageDelta' && latestChatMessageInPayload && relatedChatMessageIndex >= 0) {
        result.chatMessages[relatedChatMessageIndex].content += latestChatMessageInPayload.latestContentDelta;
      } else {
        result.chatMessages.push(latestChatMessageInPayload);
      }

      result.lastChatMessageAt = latestChatMessageInPayload.createdAt;

      return result;
    });
    // eventEmitter.emit("websocket-payload-received", ws.lastJsonMessage);
  }, [ws.lastJsonMessage, wsRef]);

  const sendMessage = useCallback(async (content: string) => {
    const conversation = currentConversationRef.current;
    if (!conversation) {
      throw new Error('No current conversation');
    }

    const newChatMessage: ChatMessage = {
      content,
      createdAt: new Date().toISOString(),
      id: ulid(),
      isResponse: false,
    };

    const updatedConversation = {
      ...conversation,
      chatMessages: [...conversation.chatMessages, newChatMessage],
    };

    setCurrentConversation(updatedConversation);

    const payload: WebSocketMessagePayload = {
      category: 'newChatMessage',
      chatMessages: updatedConversation.chatMessages,
      conversationId: updatedConversation.id,
    };
    wsRef.current.sendJsonMessage(payload);
    // eventEmitter.emit('websocket-payload-sent', payload);
  }, [currentConversationRef, wsRef]);

  const messages = currentConversation.chatMessages.map((chatMessage) => <li
    className={combineClasses(styles.chatMessage, chatMessage.isResponse ? styles.chatMessageResponse : styles.chatMessageRequest)} key={chatMessage.id}>
    <Markdown
      components={{
        a(props) {
          const { node, ...rest } = props; // eslint-disable-line @typescript-eslint/no-unused-vars
          return <a {...rest} target="_blank" />; // eslint-disable-line jsx-a11y/anchor-has-content
        },
        h1: 'h2',
        h4: 'h3',
        h5: 'h3',
        h6: 'h3',
      }}
    >{chatMessage.content}</Markdown>
    {chatMessage.content.length > 100 && <details className={styles.relatedChunks}>
      <summary>Related document chunks</summary>
      <ol>
        {chatMessage.relatedDocumentChunks?.map((chunk) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return <li key={chunk.id}><pre>{JSON.stringify(chunk, null, 2)}</pre></li>;
        })}
      </ol>
    </details>}
  </li>);

  const [textareaValue, setTextareaValue] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const resetTextarea = useCallback(() => {
    setTextareaValue('');
  }, []);

  const onTextareaChanged: React.FormEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    if (!(event.target instanceof HTMLTextAreaElement)) {
      return;
    }
    setTextareaValue(event.target.value);
  }, []);

  const onSubmit: React.FormEventHandler = useCallback((event) => {
    event?.preventDefault();
    // if (isDisabled) {
    //   return;
    // }
    if (!textareaRef.current || !textareaRef.current.value) {
      return;
    }
    sendMessage(textareaRef.current.value);
    resetTextarea();
  }, [sendMessage, resetTextarea]);

  const onKeyDown: React.KeyboardEventHandler = useCallback((event) => {
    if (event.key !== 'Enter' || event.shiftKey) {
      return;
    }
    event.preventDefault();
    return onSubmit(event);
  }, [onSubmit]);

  if (!useIsStaffProfile()) {
    return <p>Page not found</p>;
  }

  return <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.title}>
        <NewsletterIcon text="" />
        <h1>Chat</h1>
      </div>
    </div>
    <div className={styles.content}>
      <ol>{messages}</ol>
    </div>
    <div className={styles.messageFormWrapper}>
      <form
        className={styles.messageForm}
        onSubmit={onSubmit}>
        <Textarea
          classNames={{
            textarea: styles.messageField,
            wrapper: styles.messageFieldWrapper,
          }}
          onChange={onTextareaChanged}
          onKeyDown={onKeyDown}
          placeholder="Ask anything..."
          ref={textareaRef}
          rows={1}
          value={textareaValue}
        />
        <input className={styles.submitButton} type="submit" />
      </form>
    </div>
  </div>;
}
